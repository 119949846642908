import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import styled, { css } from 'styled-components';
import PinIcon from './icons/PinIcon';
import { isAccelPayMessage } from '../lib/utils';
import { LS_KEY } from '../lib/constants';
import messenger from '../messenger';

const ZipCodeInput = styled.div`
  max-height: 300px;
  overflow: hidden;
  padding: 4px;
  transition: max-height 0.25s ease-out;
  margin-bottom: 4px;

  ${props => css`
    ${props.collapsed ? 'max-height: 0px;' : ''}
  `}
`;
const P = styled.p`
  margin: 0;
`;
const Button = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;
const UpdateButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: black;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 36px 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow:
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
`;
const Loading = styled.span`
  width: 20px;
  height: 20px;
  margin: 0px 4px;
  border: 3px solid #d9d9d9;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid #d9d9d9;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
  }

  @keyframes scaleUp {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    60%,
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @keyframes pulse {
    0%,
    60%,
    100% {
      transform: scale(1);
    }
    80% {
      transform: scale(1.2);
    }
  }
`;

const GOOGLE_GEOLOCATE_KEY = 'AIzaSyCX61vPStbtfx7a1lgYFzClQuOHkz049NQ';

const ZipCodeWidget = () => {
  const address = JSON.parse(
    sessionStorage.getItem(`${LS_KEY}/address`) || '{}',
  );
  const [loading, setLoading] = useState(true);
  const [unavailable, setUnavailable] = useState(false);
  const [edit, setEdit] = useState(false);
  const [zipcode, setZipcode] = useState(address?.zip || '');
  const [inputValue, setInputValue] = useState(address?.zip || '');

  useEffect(() => {
    geolocate();

    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-dd-variant-data') {
        setLoading(false);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  const geolocate = () => {
    if (!zipcode) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          (async () => {
            const res =
              await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}
    &result_type=street_address&key=${GOOGLE_GEOLOCATE_KEY}`);

            if (res.ok) {
              const data = await res.json();
              const geocodeZip = data.results[0].address_components.find(
                component => component.types[0] == 'postal_code',
              )?.short_name;

              if (geocodeZip) {
                storeZipcode(geocodeZip);
                setZipcode(geocodeZip);
                setInputValue(geocodeZip);
                messenger.addAddress(window, '*', {
                  zip: geocodeZip,
                });
              }
            }
          })();
        },
        () => {
          setEdit(true);
          setLoading(false);
        },
      );
    }
  };

  const storeZipcode = zip => {
    sessionStorage.setItem(
      `${LS_KEY}/address`,
      JSON.stringify({
        ...address,
        zip,
      }),
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    storeZipcode(inputValue);
    setZipcode(inputValue);
    setEdit(false);
    setLoading(true);
    messenger.addAddress(window, '*', { zip: inputValue });
  };

  return (
    <div>
      <div
        id="accelpay-zipcode-summary"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex' }}>
          <P>{`Local delivery to ${zipcode || ''}`}</P>
          {loading && <Loading />}
        </div>
        <Button
          id="accelpay-edit-button"
          onClick={() => setEdit(edit => !edit)}
        >
          {edit ? 'close' : 'edit'}
        </Button>
      </div>
      {!!zipcode && unavailable && (
        <P style={{ fontSize: 11 }} id="accelpay-unavailable">
          Not Available
        </P>
      )}
      <ZipCodeInput collapsed={!edit} className="accelpay-zipcode-edit">
        <form
          id="accelpay-zipcode-form"
          class="accelpay-form"
          style={{ marginTop: 10 }}
          onSubmit={handleSubmit}
        >
          <div style="position:relative;">
            <Input
              type="text"
              name="zipcode"
              placeholder="Zip Code"
              required
              class="accelpay-input"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <span
              style={{
                position: 'absolute',
                right: '8px',
                top: '5px',
              }}
            >
              <PinIcon />
            </span>
          </div>
          <UpdateButton class="accelpay-update-button" type="submit">
            Update
          </UpdateButton>
        </form>
      </ZipCodeInput>
    </div>
  );
};

export default ZipCodeWidget;
