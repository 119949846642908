import { render, h } from 'preact';
import * as Sentry from '@sentry/browser';
import AddToCartButton from './components/AddToCartButton';
import AddToCartRadioButtons from './components/AddToCartRadioButtons';
import AddToCartVariantsDropdown from './components/AddToCartVariantsDropdown';
import AddToCartCollectionsDropdown from './components/AddToCartCollectionsDropdown';
import FulfillmentBadges from './components/FulfillmentBadges';
import DoorDashButton from './components/DoorDashButton';
import ViewCartButton from './components/ViewCartButton';
import ViewAccountButton from './components/ViewAccountButton';
import PriceLabel from './components/PriceLabel';
import DataFetcher from './components/DataFetcher';
import messenger from './messenger';
import flatten from 'flat';
import uniq from 'lodash/uniq';
import CountrySwitcher from './components/CountrySwitcher';
import AddressModalButton from './components/AddressModalButton';
import ZipCodeWidget from './components/ZipCodeWidget';

declare global {
  interface Window {
    accelpaytriage: () => void;
    google: any;
    dataLayer: Record<string, any>;
    klaviyo: any;
  }
}
declare var gtag: (...args: any[]) => void;
declare var dataLayer: Record<string, any>;

const mainStyles = `position:fixed;bottom:0;left:0;z-index:10000000001;height:100%;width:100%;overflow:hidden;border:none;background-color:#ffffff;animation-duration:.5s;`;
const sidebarStyles = `position:fixed;top: 0;right:0;z-index:10000000001;height:100%;width:100%;overflow:hidden;border:none;background-color:#ffffff;animation-duration:.5s;max-width:${
  /Mobi/.test(navigator.userAgent) && window.innerWidth < 600 ? '90%' : '425px'
} !important;`;
const modalStyles = `position:fixed;z-index:10000000001;border-radius:8px;width:400px;height:400px;top:50%;left:50%;margin-left:-200px;margin-top:-200px;overflow:hidden;border:none;background-color:#ffffff;animation-duration:.25s;`;

class dom {
  private brandId: number;
  private config: any;
  private env: 'production' | 'development' | 'local';
  private sentryTransaction: any;
  private iframe: any;
  private overlay: any;
  private stylesheet: any;
  private current: any;
  private atcEventListeners: any[];
  private viewAccountListeners: any[];
  private gopuffEnabled: boolean;
  private addressGatePresent: boolean;

  constructor(brandId, config, env) {
    this.brandId = brandId;
    this.config = config;
    this.env = env;
    this.gopuffEnabled = config?.brand_gopuff_enabled;
    this.addressGatePresent = !!document.querySelector(
      '[data-bcaddressmodalbutton]',
    );
    this.iframe = this.createIframe();
    this.overlay = this.createOverlay();
    this.stylesheet = document.createElement('style');
    this.stylesheet.appendChild(
      document.createTextNode(
        '@keyframes openMain { from {transform: translateY(100%); } to { transform: translateY(0) } }' +
          '@keyframes closeMain { from {transform: translateY(0); } to { transform: translateY(100%) } }' +
          '@keyframes openSidebar { from {transform: translateX(100%); } to { transform: translateX(0) } }' +
          '@keyframes closeSidebar { from {transform: translateX(0); } to { transform: translateX(100%) } }' +
          '@keyframes openModal { from {transform: scale(1); } to { transform: scale(1.01) } }' +
          (this.config.styles || ''),
      ),
    );
    this.insertIframeAndStylesheet();

    this.viewAccountListeners = (
      this.config.brand_accounts_icon_selectors
        ? JSON.parse(this.config.brand_accounts_icon_selectors)
        : []
    ).map(selector => ({
      selector,
      handler: e => {
        e.preventDefault();
        this.openAccount();
      },
    }));

    this.atcEventListeners = (
      this.config.product_atc_selectors
        ? JSON.parse(this.config.product_atc_selectors)
        : []
    ).map(productAtcSelector => {
      const selector = productAtcSelector.selector;
      const listingId = productAtcSelector.listingId;
      const variantId = productAtcSelector.variantId;
      const collectionId = productAtcSelector.collectionId;
      const pathname = productAtcSelector.pathname;

      return {
        selector,
        variantId,
        collectionId,
        pathname,
        handler: collectionId
          ? e => {
              messenger.addcollectiontocart(
                this.iframe.contentWindow,
                this.config?.url,
                collectionId,
              );
              if (this.openCart) {
                this.openCart();
              }
              e.preventDefault();
              return false;
            }
          : e => {
              messenger.addtocart(
                this.iframe.contentWindow,
                this.config?.url,
                listingId,
                variantId,
              );
              if (this.openCart) {
                this.openCart();
              }
              e.preventDefault();
              return false;
            },
      };
    });
  }

  getIframe() {
    return this.iframe;
  }

  getOverlay() {
    return this.overlay;
  }

  openMain() {
    this.iframe.style = mainStyles;
    this.iframe.style.animationName = 'openMain';
    this.overlay.style.display = 'block';
    document.body.style.overflow = 'hidden';
    this.current = 'main';
  }

  closeMain() {
    document.body.style.overflow = 'scroll';
    this.overlay.style.display = 'none';
    this.iframe.style.animationName = 'closeMain';
    this.iframe.style.transform = 'translateY(100%)';
  }

  openSidebar() {
    this.iframe.style = sidebarStyles;
    this.iframe.style.animationName = 'openSidebar';
    this.overlay.style.display = 'block';
    document.body.style.overflow = 'hidden';
    this.current = 'sidebar';
  }

  closeSidebar() {
    document.body.style.overflow = 'scroll';
    this.overlay.style.display = 'none';
    this.iframe.style.animationName = 'closeSidebar';
    this.iframe.style.transform = 'translateX(100%)';
  }

  openModal() {
    this.iframe.style = modalStyles;
    this.iframe.style.animationName = 'openModal';
    this.overlay.style.display = 'block';
    this.current = 'modal';
  }

  closeModal() {
    document.body.style.overflow = 'scroll';
    this.iframe.style = sidebarStyles;
    this.iframe.style.transform = 'translateX(100%)';
    this.overlay.style.display = 'none';
  }

  openCart() {
    messenger.viewCart(this.iframe.contentWindow, this.config.url);
    this.openSidebar();
  }

  closeCart() {
    this.closeSidebar();
  }

  openAccount() {
    messenger.viewAccount(this.iframe.contentWindow, this.config.url);
    this.openMain();
  }

  closeAccount() {
    this.closeMain();
  }

  openAddressModal() {
    messenger.viewAddressModal(this.iframe.contentWindow, this.config.url);
    this.openModal();
  }

  closeAddressModal() {
    this.closeModal();
  }

  createIframe() {
    let sentryIframe;
    Sentry.startSpanManual(
      {
        name: 'iframe-create',
      },
      span => {
        sentryIframe = span;
      },
    );

    const root = document.createElement('iframe');
    const isCartOpen = window.location.hash === '#checkout';

    root.setAttribute(
      'style',
      `${mainStyles}transform:translateY(${isCartOpen ? '0' : '100%'});`,
    );

    root.setAttribute('id', 'accelpay-iframe');
    root.setAttribute('allowpaymentrequest', '');
    root.setAttribute('allow', 'payment');
    root.setAttribute('title', this.config.title || 'Accelpay Cart');
    root.onload = e => {
      sentryIframe.end();
      // Message the buttons that were loaded after iframe is inserted
      messenger.productsViewed(
        this.iframe.contentWindow,
        this.config.url,
        Array.from(
          document.querySelectorAll(
            '[data-bclistingid], [data-bccollectionid]',
          ),
        )
          .map(item => {
            const listingId = item.getAttribute('data-bclistingid');
            const variantId = item.getAttribute('data-bcvariantid');
            return {
              variantId,
              listingId,
            };
          })
          .filter(item => item.listingId),
      );
    };

    root.src = `${this.config.url || ''}/${this.brandId}/cart${
      document.location.search
    }`;
    return root;
  }

  createOverlay() {
    const root = document.createElement('div');
    const isCartOpen = window.location.hash === '#checkout';

    root.setAttribute(
      'style',
      `position:fixed;z-index:9999;top:0;width:100%;height:100%;opacity:.5;background:#000;display:${
        isCartOpen ? 'block' : 'none'
      };`,
    );

    root.onclick = () => {
      if (this.current === 'sidebar') {
        this.closeSidebar();
      } else if (this.current === 'modal') {
        this.closeModal();
      } else {
        this.closeMain();
        messenger.close(this.iframe.contentWindow, this.config.url);
      }
    };

    return root;
  }

  attachAllElements() {
    let current;
    while ((current = apbrand.listeners?.pop())) {
      window.removeEventListener('message', current, false);
    }

    console.log('attachAllElements');
    this.attachCartElements();
    this.attachPriceLabels();
    this.attachAddToCartButtons();
    this.attachEventsFromDataAttrs();
    this.attachEventsFromBrandConfig();
    this.attachAddressModalButton();
    this.attachBadges();
    this.attachDoorDashButtons();
    this.attachZipcodeWidget();
    this.attachDataFetcher();
  }

  attachAddToCartButtons() {
    try {
      const accelpayButtons = Array.from(
        document.querySelectorAll(
          '[data-bclistingid]:not([data-bclink]), [data-bccollectionid]:not([data-bcpricelabel]):not([data-bclink]), [data-bccollectionids]:not([data-bclink])',
        ),
      );

      console.debug('Found listings:', accelpayButtons);
      accelpayButtons.forEach(item => {
        const listingId = item.getAttribute('data-bclistingid')?.trim();
        const variantId = item.getAttribute('data-bcvariantid')?.trim();

        const collectionId = item.getAttribute('data-bccollectionid')?.trim();
        const collectionIds = item.getAttribute('data-bccollectionids')?.trim();
        const collectionLabels = item
          .getAttribute('data-bccollectionlabels')
          ?.trim();

        const hasMultipleOption =
          item.getAttribute('data-hasmultipleoption') &&
          this.config.product_options &&
          JSON.parse(this.config.product_options)[listingId] &&
          this.config.variant_ids &&
          JSON.parse(this.config.variant_ids)[listingId];

        const buttonItem = item.querySelector('button');
        const buttonText = (
          buttonItem?.textContent ||
          item?.textContent ||
          ''
        ).trim();
        item.textContent = '';
        if (buttonItem) {
          buttonItem.textContent = '';
        }

        if (collectionIds) {
          render(
            <AddToCartCollectionsDropdown
              collectionIds={collectionIds.split(',')}
              collectionLabels={collectionLabels?.split(',')}
              config={this.config}
              iframe={this.iframe}
              openCart={this.openCart.bind(this)}
            >
              {buttonText}
            </AddToCartCollectionsDropdown>,
            item,
          );
        } else if (hasMultipleOption) {
          render(
            <AddToCartVariantsDropdown
              listingId={listingId}
              config={this.config}
              iframe={this.iframe}
              openCart={this.openCart.bind(this)}
            >
              {buttonText}
            </AddToCartVariantsDropdown>,
            item,
          );
        } else if (variantId || collectionId) {
          render(
            <AddToCartButton
              listingId={listingId}
              variantId={variantId}
              collectionId={collectionId}
              config={this.config}
              apiEnv={this.env}
              iframe={this.iframe}
              openCart={this.openCart.bind(this)}
              openAddressGate={this.openAddressModal.bind(this)}
              promptAddressGate={this.gopuffEnabled}
              addressGatePresent={this.addressGatePresent}
            >
              {buttonText}
            </AddToCartButton>,
            item,
          );
        } else {
          // [LEGACY] variant selection
          const variantIds = item.getAttribute('data-bcvariantids');
          const variantLabels = item.getAttribute('data-bcvariantlabels');
          if (variantIds) {
            render(
              <AddToCartRadioButtons
                listingId={listingId}
                variantIds={variantIds.split(',')}
                variantLabels={variantLabels.split(',')}
                config={this.config}
                iframe={this.iframe}
                openCart={this.openCart.bind(this)}
              >
                {buttonText}
              </AddToCartRadioButtons>,
              item,
            );
          }
        }
      });
    } catch (e) {
      console.error('error', e);
    }
  }

  attachEventsFromDataAttrs() {
    try {
      const accelpayButtons = Array.from(
        document.querySelectorAll(
          '[data-bclink][data-bclistingid], [data-bclink][data-bccollectionid]:not([data-bcpricelabel])',
        ),
      );

      accelpayButtons.forEach(item => {
        const listingId = item.getAttribute('data-bclistingid')?.trim();
        const variantId = item.getAttribute('data-bcvariantid')?.trim();
        const collectionId = item.getAttribute('data-bccollectionid')?.trim();

        this._addClickEvents(item, listingId, variantId, collectionId);
      });
    } catch (e) {
      console.error('error', e);
    }
  }

  attachBadges() {
    const badges = Array.from(document.querySelectorAll('[data-bcbadges]'));
    badges.forEach(item => {
      const variantId = item.getAttribute('data-bcvariantid')?.trim();
      render(<FulfillmentBadges variantId={variantId} />, item);
    });
  }

  attachDoorDashButtons() {
    const buttons = Array.from(
      document.querySelectorAll('[data-bcddlink][data-bcvariantid]'),
    );
    buttons.forEach(item => {
      const variantId = item.getAttribute('data-bcvariantid')?.trim();
      render(<DoorDashButton variantId={variantId} />, item);
    });
  }

  attachZipcodeWidget() {
    const widgets = Array.from(document.querySelectorAll('[data-bczipcode]'));
    widgets.forEach(item => {
      render(<ZipCodeWidget />, item);
    });
  }

  attachEventListeners(selector, handler) {
    const items = Array.from(document.querySelectorAll(selector));
    items.forEach(item => {
      if (this.iframe && this.config) {
        item.style.cursor = 'pointer';
        item.removeEventListener('click', handler, false);
        item.addEventListener('click', handler, false);
      }
    });
  }

  attachEventsFromBrandConfig() {
    try {
      /** Add to cart buttons */
      this.atcEventListeners.forEach(({ pathname, selector, handler }) => {
        if (pathname === '*' || pathname === location.pathname) {
          this.attachEventListeners(selector, handler);
        }
      });

      /** View account buttons */
      this.viewAccountListeners.forEach(({ selector, handler }) => {
        this.attachEventListeners(selector, handler);
      });
    } catch (e) {
      console.error('error', e);
    }
  }

  updateEventsFromBrandConfig(variantMap) {
    this.atcEventListeners.forEach(
      ({ pathname, selector, variantId, collectionId }) => {
        if (pathname === '*' || pathname === location.pathname) {
          const items = Array.from(document.querySelectorAll(selector));
          items.forEach(item => {
            if (!collectionId) {
              // Only disable button for variants
              const variant = variantMap[variantId];
              const preorder = variant?.availabilityStatus === 'preorder';
              const available =
                variant &&
                ['available', 'preorder'].includes(variant.inventoryStatus);
              if (!available) {
                item.classList.add('accelpay-btn-oos');
                item.style.pointerEvents = 'none';
                if (item.tagName?.toLowerCase() === 'button') {
                  item.disabled = true;
                }
                /** If button only contains text, update to OOS copy */
                if (item.innerText && !item.innerHTML.includes('<img ')) {
                  item.innerText = this.config.oos_text || 'Sold Out';
                }
              } else if (preorder) {
                if (item.innerText && !item.innerHTML.includes('<img ')) {
                  item.innerText = 'PRE-ORDER';
                }
              } else if (this.config.button_text) {
                if (item.innerText && !item.innerHTML.includes('<img ')) {
                  item.innerText = this.config.button_text;
                }
              }
            }
          });
        }
      },
    );
  }

  renderFloatingIconButtons() {
    if (document.body.querySelector('.accelpay-icon-buttons') === null) {
      const loc = document.createElement('div');
      document.body.appendChild(loc);
      render(
        <div
          className="accelpay-icon-buttons"
          style={`display:flex;align-items:flex-start;z-index:1000;position:fixed;top:100px;right:0px;flex-direction:column;align-items:center;${this.config.icon_styles}`}
        >
          <ViewCartButton
            config={this.config}
            openCart={this.openCart.bind(this)}
          />
        </div>,
        loc,
      );
    }
  }

  insertViewCartIcon(parent) {
    if (parent.querySelector('.accelpay-view-cart') === null) {
      const loc = document.createElement('div');
      parent.appendChild(loc);
      render(
        <ViewCartButton
          config={this.config}
          openCart={this.openCart.bind(this)}
          embedded
        />,
        loc,
      );
    }
  }

  insertAccountsIcon(parent) {
    if (parent.querySelector('.accelpay-view-account') === null) {
      const loc = document.createElement('div');
      parent.appendChild(loc);
      render(
        <ViewAccountButton
          config={this.config}
          openAccount={this.openAccount.bind(this)}
          embedded
        />,
        loc,
      );
    }
  }

  insertCountrySwitcher(loc) {
    render(<CountrySwitcher config={this.config} iframe={this.iframe} />, loc);
  }

  insertIframeAndStylesheet() {
    !document.body.contains(this.overlay) &&
      document.body.appendChild(this.overlay);
    !document.body.contains(this.iframe) &&
      document.body.appendChild(this.iframe);
    !document.body.contains(this.stylesheet) &&
      document.body.appendChild(this.stylesheet);
  }

  attachCartElements() {
    try {
      /** Handle View Cart Buttons */
      if (this.config.icon_parent) {
        const parent: any = document.querySelectorAll(this.config.icon_parent);
        for (const loc of parent) {
          this.insertViewCartIcon(loc);
          console.debug('[brand.js] insert CART icon into: ', loc);
        }
      } else {
        const useMarkup: any = document.querySelectorAll('[data-bccartbutton]');
        if (useMarkup.length > 0) {
          for (const loc of useMarkup) {
            this.insertViewCartIcon(loc);
          }
        } else {
          this.renderFloatingIconButtons();
        }
      }

      /** Handle View Account Buttons */
      if (this.config.brand_accounts_icon_parent) {
        const parent: any = document.querySelectorAll(
          this.config.brand_accounts_icon_parent,
        );
        for (const loc of parent) {
          this.insertAccountsIcon(loc);
          console.debug('[brand.js] insert ACCOUNT icon into: ', loc);
        }
      } else {
        const useMarkup: any = document.querySelectorAll(
          '[data-bcaccountbutton]',
        );
        for (const loc of useMarkup) {
          this.insertAccountsIcon(loc);
        }
      }

      /** Handle Country Switcher */
      if (this.config.brand_country_switcher_parent) {
        const parent: any = document.querySelectorAll(
          this.config.brand_country_switcher_parent,
        );
        for (const loc of parent) {
          this.insertCountrySwitcher(loc);
          console.debug('[brand.js] insert Country Switcher into: ', loc);
        }
      }

      /** Insert these again in case the setup function was called separately */
      this.insertIframeAndStylesheet();
      console.debug('append iframe to dom');
    } catch (e) {
      console.error('error', e);
    }
  }

  attachPriceLabels() {
    const labels = Array.from(document.querySelectorAll('[data-bcpricelabel]'));
    labels.forEach(el => {
      const variantId = el.getAttribute('data-bcvariantid')?.trim();
      const variantIds = el.getAttribute('data-bcvariantids')?.trim();
      const collectionId = el.getAttribute('data-bccollectionid')?.trim();
      const mode = el.getAttribute('data-bcpricelabel')?.trim();
      render(
        <PriceLabel
          variantId={variantId}
          variantIds={
            variantIds ? variantIds.split(',').map(id => id.trim()) : null
          }
          collectionId={collectionId}
          mode={mode}
        />,
        el,
      );
    });
  }

  attachAddressModalButton() {
    try {
      const locations: any = document.querySelectorAll(
        '[data-bcaddressmodalbutton]',
      );
      for (const loc of locations) {
        render(
          <AddressModalButton
            openAddressModal={() => this.openAddressModal()}
          />,
          loc,
        );
      }
    } catch (e) {
      console.error('error', e);
    }
  }

  attachDataFetcher() {
    const fetcherContainer = document.createElement('div');
    document.body.appendChild(fetcherContainer);

    /** List of Variant IDs for our add to cart listeners */
    const atcSelectorVariantIds = this.atcEventListeners.map(
      ({ variantId }) => variantId,
    );

    /** List of Variant IDs for our html add to cart buttons */
    const variantElements: any = document.querySelectorAll(
      '[data-bcvariantid]:not([data-bcddlink]), [data-bcvariantids]',
    );
    const htmlVariantIds = Array.from(variantElements).reduce(
      (acc: string[], el: Element) => {
        const ids = (
          el.getAttribute('data-bcvariantid') ||
          el.getAttribute('data-bcvariantids')
        )
          ?.split(',')
          .map(id => id.trim());
        return [...acc, ...(ids || [])];
      },
      [],
    ) as any;

    /** List of DoorDash Variant IDs */
    const doordashVariantElements: any = document.querySelectorAll(
      '[data-bcvariantid][data-bcddlink]',
    );
    const doordashVariantIds = Array.from(doordashVariantElements).map(
      (el: any) => el.getAttribute('data-bcvariantid'),
    );

    /** List of Variant IDs for our variant dropdown widget */
    const dropdownVariantIds = Object.values(
      flatten(
        this.config.variant_ids ? JSON.parse(this.config.variant_ids) : {},
      ),
    );

    /** List of Collection IDs we need to fetch data for */
    const collectionElements: any = document.querySelectorAll(
      '[data-bccollectionids], [data-bccollectionid]',
    );
    const collectionIds = Array.from(collectionElements).reduce(
      (acc: string[], el: Element) => {
        const ids = (
          el.getAttribute('data-bccollectionids') ||
          el.getAttribute('data-bccollectionid')
        )
          ?.split(',')
          .map(id => id.trim());
        return [...acc, ...(ids || [])];
      },
      [],
    ) as any;

    console.debug('[dom] hasLocalDelivery', this.addressGatePresent);
    render(
      <DataFetcher
        brandId={this.brandId}
        variantIds={uniq([
          ...atcSelectorVariantIds,
          ...htmlVariantIds,
          ...dropdownVariantIds,
        ])}
        doordashVariantIds={doordashVariantIds}
        collectionIds={uniq(collectionIds)}
        sentryTransaction={this.sentryTransaction}
        apiEnv={this.env}
        hasLocalDelivery={this.addressGatePresent}
      />,
      fetcherContainer,
    );
  }

  attachTriageFn() {
    window.accelpaytriage = () => {
      messenger.debug(this.iframe.contentWindow, this.config.url);
    };
  }

  attachGoogleAnalytics() {
    const googleAnalyticsID = this.config.brand_google_analytics_id;
    if (googleAnalyticsID) {
      try {
        if (typeof gtag === 'undefined') {
          const script = document.createElement('script');
          script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`;
          script.async = true;
          const gtag = document.createElement('script');
          gtag.text = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${googleAnalyticsID}');`;
          document.body.appendChild(script);
          document.body.appendChild(gtag);
        } else {
          gtag('config', googleAnalyticsID);
        }
      } catch (e) {
        console.error('error', e);
      }
    }
  }

  attachKlaviyo() {
    const key = this.config.brand_klaviyo_pub_key || 'WgvGxc';

    try {
      const src = `//static.klaviyo.com/onsite/js/klaviyo.js`;
      const exists = document.querySelector(`script[src*="${src}"]`);
      if (!exists) {
        const script = document.createElement('script');
        script.src = `${src}?company_id=${key}`;
        document.body.appendChild(script);
      }
      return exists;
    } catch (e) {}
  }

  _addClickEvents(domElement, listingId, variantId, collectionId) {
    if (this.iframe && this.config) {
      if (collectionId) {
        domElement.addEventListener(
          'click',
          e => {
            console.log('collection click');
            messenger.addcollectiontocart(
              this.iframe.contentWindow,
              this.config?.url,
              collectionId,
            );
            if (this.openCart) {
              this.openCart();
            }
            e.preventDefault();
            return false;
          },
          false,
        );
      } else if (listingId && variantId) {
        domElement.addEventListener(
          'click',
          e => {
            console.log('variant click');
            messenger.addtocart(
              this.iframe.contentWindow,
              this.config?.url,
              listingId,
              variantId,
            );
            if (this.openCart) {
              this.openCart();
            }
            e.preventDefault();
            return false;
          },
          false,
        );
      }
    }
  }
}

export default dom;
