import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import messenger from '../messenger';
import client from '../lib/api';
import {
  isAccelPayMessage,
  getAddress,
  objectToQueryString,
} from '../lib/utils';

const DataFetcher = ({
  brandId,
  variantIds = [],
  doordashVariantIds = [],
  collectionIds = [],
  sentryTransaction,
  apiEnv,
  hasLocalDelivery = false,
  currency = 'USD',
}: any) => {
  const fetchedIds = useRef({});
  const api = new client(apiEnv);

  const fetchAvailability = async (queryParams?: any) => {
    const chunkSize = 100;
    let availability = {};
    const ids = queryParams?.ids || variantIds;

    for (let i = 0; i < ids.length; i += chunkSize) {
      const chunk = ids.slice(i, i + chunkSize);
      const availabilityRes = await api.get(
        `/variants/availability?${objectToQueryString({
          ids: chunk,
          ...queryParams,
        })}`,
      );
      if (availabilityRes.ok) {
        const chunkAvailability = (await availabilityRes.json()) || {};
        availability = { ...availability, ...chunkAvailability };
      }
    }

    return Object.keys(availability).reduce((acc, variantId) => {
      if (fetchedIds.current[variantId]) {
        const { status, priceMin, priceMax } =
          availability[variantId][currency];

        acc[variantId] = {
          ...fetchedIds.current[variantId],
          inventoryStatus: status,
          priceMin,
          priceMax,
        };
      }
      return acc;
    }, {});
  };

  const fetchAllAvailability = async (address?: any) => {
    console.debug('[datafetcher] address', address);
    if (variantIds?.length > 0) {
      const variantData = await fetchAvailability({
        ids: variantIds,
        retailerTypes: ['standard', 'merch_only', 'self_fulfiller'],
        zip: address?.zip,
      });
      messenger.variantData(window, '*', variantData);
    }

    if (doordashVariantIds.length) {
      const doordashVariantData = await fetchAvailability({
        ids: doordashVariantIds,
        retailerTypes: ['delivery_only'],
        zip: address?.zip,
      });
      messenger.doordashVariantData(window, '*', doordashVariantData);
    }

    if (
      hasLocalDelivery &&
      !!address?.line1?.trim() &&
      !!address?.zip?.trim()
    ) {
      const gopuffRes = await api.get(
        `/hooks/gopuff/retailer?${objectToQueryString({
          address: `${address?.line1?.trim() || ''} ${
            address?.line2?.trim() || ''
          }`,
          city: address?.city,
          state: address?.state,
          zip: address?.zip,
          country: address?.country,
        })}`,
      );
      if (gopuffRes.ok) {
        const gopuffRetailer = await gopuffRes.json();
        const gopuffVariantData = await fetchAvailability({
          ids: variantIds,
          retailerTypes: ['gopuff'],
          ignoreInventory: false,
          'retailerIds[]': gopuffRetailer.id,
        });
        messenger.gopuffVariantData(window, '*', gopuffVariantData);
      }
    }
  };

  const init = async (address?: any) => {
    const sentryFetch = sentryTransaction?.startChild({
      op: 'fetch',
      description: 'variants',
    });
    const variantIdsToFetch = [...variantIds, ...doordashVariantIds].filter(
      id => !fetchedIds.current[id],
    );
    const collectionIdsToFetch = [...collectionIds].filter(
      id => !fetchedIds.current[id],
    );

    if (variantIdsToFetch.length > 0) {
      const variantsRes = await api.get(
        `/variants?ids=${JSON.stringify(variantIdsToFetch)}`,
      );
      if (variantsRes.ok) {
        const variants = await variantsRes.json();
        const result = variants.reduce((acc: any, variant: any) => {
          acc[variant.id] = variant;
          return acc;
        }, {});
        fetchedIds.current = { ...fetchedIds.current, ...result };
      } else {
        sentryFetch?.setStatus(sentryFetch?.UnknownError);
      }
    }
    fetchAllAvailability(address);
    if (collectionIdsToFetch.length) {
      const collectionsRes = await api.get(
        `/brands/${brandId}/collections?ids=${JSON.stringify(
          collectionIdsToFetch,
        )}`,
      );
      if (collectionsRes.ok) {
        const collections = await collectionsRes.json();
        fetchedIds.current = {
          ...fetchedIds.current,
          ...collections.reduce((acc, collection) => {
            acc[collection.id] = collection;
            return acc;
          }, {}),
        };
        messenger.collectionData(
          window,
          '*',
          collections.reduce((acc, collection) => {
            acc[collection.id] = collection;
            return acc;
          }, {}),
        );
      }
    }

    sentryFetch?.finish();
  };

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-add-address') {
        fetchAllAvailability(value?.address);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  useEffect(() => {
    const address = getAddress();
    init(address);
  }, [variantIds, collectionIds]);

  return null;
};

export default DataFetcher;
