import { STAGING_SITES } from './lib/constants';
import AccelPayIntegration from './accelpay';

var isProduction = !STAGING_SITES.some(site =>
  window.location.hostname.includes(site),
);
const AP_ENV = (window.AP_ENV = isProduction ? 'production' : 'development');

if (!apbrand?.accelpay) {
  const accelpay = new AccelPayIntegration(AP_ENV);
  accelpay.init();
  console.debug('init ap');
  apbrand.accelpay = accelpay;
  apbrand.state = {};
  apbrand.listeners = [];
}
