import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styled, { css } from 'styled-components';
import { isAccelPayMessage } from '../lib/utils';

const Root = styled.button`
  border: none;
  background: none;
  justify-content: flex-start;
  z-index: 10000;
  cursor: pointer;
  padding: 6px;

  ${props => css`
    ${props.count > 0 || props.embedded ? 'display: block;' : 'display: none;'}
  `}
`;

const CartBox = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CartCount = styled.div`
  position: absolute;
  top: 10px;
  right: 6px;
  width: 10px;
  height: 10px;
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  border-radius: 7px;
  background-color: #f472b6;
  opacity: 0.8;
  color: #fff;
  font-family:
    Circular,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    'Helvetica Neue',
    sans-serif;

  ${props => css`
    ${props.config.item_count_styles || ''}
  `};
`;

const LS_KEY = 'accelpay/cart-count';

const ViewCartButton = ({ config, openCart, embedded }: any) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-update') {
        setCount(parseInt(value, 10));
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  return (
    <Root
      className="accelpay-view-cart bc-cartcount-wrapper"
      aria-label="View Cart"
      count={count}
      config={config}
      embedded={embedded}
      onClick={() => {
        if (openCart) {
          openCart();
        }
      }}
    >
      <CartBox config={config}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke={config.icon_svg_color || '#000'}
          style={{ width: '25px', height: '25px', margin: '4px' }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>

        {count > 0 && (
          <CartCount
            className="accelpay-cart-count"
            config={config}
          ></CartCount>
        )}
      </CartBox>
    </Root>
  );
};

export default ViewCartButton;
