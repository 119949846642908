import { LS_KEY } from './constants';

export const isAccelPayMessage = e => /^bc\-.*/g.test(e?.data?.action);

export const formatPrice = (
  amount: number,
  locale = 'en-US',
  currency = 'USD',
) =>
  typeof amount === 'number'
    ? new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
        amount / 100,
      )
    : '';

export const getAddress = () =>
  JSON.parse(sessionStorage.getItem(`${LS_KEY}/address`) || '{}');

export const addressToString = address =>
  address?.line1
    ? `${address.line1}${address.line2 ? ` ${address.line2}` : ''} ${
        address.city
      }, ${address.state}`
    : '';

export const capitalizeFirstLetter = (string?: string) => {
  if (!string || !string.length) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/** Serialize object to url query string */
export const objectToQueryString = function (obj: any, prefix?: string) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      if (v !== undefined) {
        str.push(
          v !== null && typeof v === 'object'
            ? objectToQueryString(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v),
        );
      }
    }
  }
  return str.join('&');
};
