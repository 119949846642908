import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import PinIcon from './icons/PinIcon';
import { isAccelPayMessage } from '../lib/utils';
import { getAddress, addressToString } from '../lib/utils';
import { LS_KEY } from '../lib/constants';

const Icon = styled.div`
  height: 1.75rem;
  width: 1.75rem;
  padding: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  width: 225px;
  position: relative;
  border: 1px solid #000;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
`;

const Text = styled.span`
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  text-align: left;
  margin-top: 2px;
`;

const H6 = styled.h6`
  font-size: 14px;
  margin: 0 0 12px 0;
`;
const P = styled.p`
  margin: 0;
`;

const Popover = styled.div`
  position: absolute;
  width: 300px;
  top: 50px;
  background: #373d3f;
  padding: 16px;
  border-radius: 4px;
  font-size: 12px;
  color: white;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #373d3f transparent;
    top: -20px;
    left: 90px;
  }
`;

const AddressModalButton = ({ openAddressModal }) => {
  const [address, setAddress] = useState(getAddress());
  const [showPopover, setShowPopover] = useState(false);
  const noAddress = !address?.line1;

  useEffect(() => {
    try {
      const handleMessage = e => {
        if (!isAccelPayMessage(e)) {
          return;
        }

        const { action, value } = e.data;
        console.debug(`[address button] ${action}`, value);
        if (action === 'bc-add-address') {
          if (value.address) {
            setAddress(value.address);
            sessionStorage.setItem(
              `${LS_KEY}/address`,
              JSON.stringify(value.address),
            );
          }
        }
      };

      window.addEventListener('message', handleMessage, false);
      console.debug('address listeners', window.apbrand?.listeners);
      window.apbrand?.listeners?.push(handleMessage);

      const addressCookie = sessionStorage.getItem(`${LS_KEY}/address`);
      if (addressCookie) {
        setAddress(JSON.parse(addressCookie));
      }
    } catch (e) {
      console.error('Error attaching address listener', e);
    }
  }, []);

  return (
    <div style={{ position: 'relative', margin: '0 8px' }}>
      <Button
        className="accelpay-delivery-method-button"
        onClick={() => {
          if (openAddressModal) {
            openAddressModal();
          }
        }}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <Icon>
          <PinIcon />
        </Icon>
        <Text>{!noAddress ? addressToString(address) : 'Enter Address'}</Text>
      </Button>
      {showPopover && (
        <Popover>
          {noAddress ? (
            <P>Enter your address here to check product availability</P>
          ) : (
            <div>
              <P>
                Displaying items available in your area. To change your address,
                open the address menu. Note: Alcohol items can't be combined for
                delivery and shipping
              </P>
            </div>
          )}
        </Popover>
      )}
    </div>
  );
};

export default AddressModalButton;
