import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import classNames from 'classnames';
import messenger from '../messenger';
import Button from './Button';
import {
  isAccelPayMessage,
  getAddress,
  objectToQueryString,
} from '../lib/utils';
import client from '../lib/api';

interface ButtonProps {
  listingId?: string;
  variantId?: string;
  collectionId?: string;
  config?: any;
  apiEnv?: string;
  iframe?: any;
  openCart?: any;
  openAddressGate?: any;
  fullWidth?: boolean;
  children?: any;
  variantMap?: any;
  promptAddressGate?: boolean;
  addressGatePresent?: boolean;
}

const AddToCartButton = ({
  listingId,
  variantId,
  collectionId,
  apiEnv,
  config,
  iframe,
  openCart,
  openAddressGate,
  addressGatePresent,
  fullWidth,
  children,
}: ButtonProps) => {
  const [available, setAvailable] = useState(true);
  const [preorder, setPreorder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [variantMap, setVariantMap] = useState({});
  const [gopuffVariantMap, setGopuffVariantMap] = useState({});
  const [collection, setCollection] = useState();
  const [address, setAddress] = useState(getAddress());
  const isGopuffEnabled = config?.brand_gopuff_enabled;
  const directToAddressGate =
    isGopuffEnabled &&
    addressGatePresent &&
    !address?.zip &&
    !loading &&
    !available;

  const fetchCollectionAvailability = async (collection: any) => {
    try {
      setLoading(true);
      const api = new client(apiEnv);
      const variantIds = collection?.listings?.map(
        listing => listing.variantId,
      );
      if (variantIds?.length > 0) {
        const variantsRes = await api.get(
          `/variants/availability?${objectToQueryString({
            ids: variantIds,
            ...(address || {}),
            retailerTypes: [
              'standard',
              'merch_only',
              'self_fulfiller',
              ...(isGopuffEnabled && addressGatePresent && address?.zip
                ? ['gopuff']
                : []),
            ],
          })}`,
        );
        const variants = await variantsRes.json();
        setAvailable(
          collection?.listings?.every(
            listing => variants[listing.variantId]?.USD?.status === 'available',
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-variant-data' && !collectionId) {
        setVariantMap(value);
        setLoading(false);
      } else if (action === 'bc-collection-data' && collectionId) {
        // Collections require fetching availability separately since we need the collection data first

        const collection = value[collectionId];
        console.log(
          `Add to cart button for collection #${collectionId} received collection data`,
          collection,
        );
        setCollection(collection);
      } else if (action === 'bc-gopuff-variant-data') {
        setGopuffVariantMap(value);
        setLoading(false);
      } else if (action === 'bc-add-address') {
        setAddress(value.address);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, [apiEnv, collectionId]);

  useEffect(() => {
    if (!(listingId && variantId)) {
      setAvailable(false);
      return;
    }

    const variant = variantMap[variantId];
    const gopuffVariant = gopuffVariantMap[variantId];
    setAvailable(
      ['available', 'preorder'].includes(variant?.inventoryStatus) ||
        ['available', 'preorder'].includes(gopuffVariant?.inventoryStatus),
    );
    setPreorder(
      variant?.availabilityStatus === 'preorder' ||
        gopuffVariant?.availabilityStatus === 'preorder',
    );
  }, [variantMap, gopuffVariantMap, listingId, variantId]);

  useEffect(() => {
    if (collection) {
      fetchCollectionAvailability(collection);
    }
  }, [collection, address]);

  const onClick = useCallback(() => {
    if (directToAddressGate) {
      if (openAddressGate) {
        openAddressGate();
      }
    } else if (iframe && config) {
      if (collectionId) {
        messenger.addcollectiontocart(
          iframe.contentWindow,
          config?.url,
          collectionId,
        );
      } else if (listingId && variantId) {
        messenger.addtocart(
          iframe.contentWindow,
          config?.url,
          listingId,
          variantId,
        );
      }

      if (openCart) {
        openCart();
      }
    }
  }, [listingId, variantId, collectionId, address, directToAddressGate]);

  return (
    <Button
      config={config}
      isAvailable={!loading && (available || directToAddressGate)}
      className={classNames(
        'accelpay-btn',
        { 'accelpay-btn-oos': !available && !directToAddressGate },
        ...(config?.button_classnames
          ? config?.button_classnames.split(',')
          : []),
      )}
      onClick={onClick}
      fullWidth={fullWidth}
    >
      {loading
        ? 'Checking...'
        : !!children && children !== 'Checking...'
          ? children
          : directToAddressGate
            ? 'Find Nearby'
            : !available
              ? config?.oos_text || 'Sold Out'
              : preorder
                ? 'PRE-ORDER'
                : config?.button_text || 'ADD TO CART'}
    </Button>
  );
};

export default AddToCartButton;
