import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import AddToCartButton from './AddToCartButton';
import styled from 'styled-components';
import { isAccelPayMessage } from '../lib/utils';

const Select = styled.select`
  width: 100%;
  border: 1px solid #818a91;
  vertical-align: middle;
  background-color: #ffffff;
  minheight: 40px;
  border-radius: 3px;
  color: #373a3c;
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
`;

const AddToCartCollectionsDropdown = ({
  collectionIds,
  collectionLabels,
  config,
  iframe,
  openCart,
  children,
}: any) => {
  const [selectedCollectionId, setSelectedCollectionId] = useState(
    collectionIds[0],
  );
  const [collectionMap, setCollectionMap] = useState({});

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-collection-data') {
        setCollectionMap(value);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  if (!collectionIds) {
    return null;
  }

  return (
    <div>
      <div className="accelpay-dropdowns" style={{ marginBottom: '8px' }}>
        <Select
          title="Collection Dropdown"
          value={selectedCollectionId}
          onChange={e => setSelectedCollectionId(e.target.value)}
        >
          {collectionIds.map((id, i) => (
            <option value={id}>
              {collectionLabels
                ? collectionLabels[i]
                : collectionMap[id]?.title}
            </option>
          ))}
        </Select>
      </div>
      <AddToCartButton
        fullWidth
        collectionId={selectedCollectionId}
        config={config}
        iframe={iframe}
        openCart={openCart}
      >
        {children}
      </AddToCartButton>
    </div>
  );
};

export default AddToCartCollectionsDropdown;
