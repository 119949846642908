var variantData = (target, url, map) => {
  target.postMessage(
    {
      action: 'bc-variant-data',
      value: map,
    },
    url,
  );
};

var doordashVariantData = (target, url, map) => {
  target.postMessage(
    {
      action: 'bc-dd-variant-data',
      value: map,
    },
    url,
  );
};

var gopuffVariantData = (target, url, map) => {
  target.postMessage(
    {
      action: 'bc-gopuff-variant-data',
      value: map,
    },
    url,
  );
};

var location = (target, url, location) => {
  target.postMessage(
    {
      action: 'bc-location',
      value: location,
    },
    url,
  );
};

var collectionData = (target, url, map) => {
  target.postMessage(
    {
      action: 'bc-collection-data',
      value: map,
    },
    url,
  );
};

var viewCart = (target, url) => {
  target.postMessage(
    {
      action: 'bc-view-cart',
    },
    url,
  );
};

var viewCheckout = (target, url) => {
  target.postMessage(
    {
      action: 'bc-view-checkout',
    },
    url,
  );
};

var viewAccount = (target, url) => {
  target.postMessage(
    {
      action: 'bc-view-account',
    },
    url,
  );
};

var viewAddressModal = (target, url) => {
  target.postMessage(
    {
      action: 'bc-view-address-modal',
    },
    url,
  );
};

var close = (target, url) => {
  target.postMessage(
    {
      action: 'bc-closed',
    },
    url,
  );
};

var productsViewed = (target, url, products) => {
  target.postMessage(
    {
      action: 'bc-products-viewed',
      value: products,
    },
    url,
  );
};

var addtocart = (target, url, listingId, variantId) => {
  target.postMessage(
    {
      action: 'bc-add',
      key: listingId,
      meta: variantId,
      value: 1,
    },
    url,
  );
};

var addcollectiontocart = (target, url, collectionId) => {
  target.postMessage(
    {
      action: 'bc-add-collection',
      key: collectionId,
      value: 1,
    },
    url,
  );
};

var addAddress = (target, url, address) => {
  target.postMessage(
    {
      action: 'bc-add-address',
      value: { address },
    },
    url,
  );
};

var applepay = (target, url) => {
  target.postMessage(
    {
      action: 'bc-applepay',
    },
    url,
  );
};

var debug = (target, url) => {
  target.postMessage(
    {
      action: 'bc-debug',
    },
    url,
  );
};

export default {
  addAddress,
  addtocart,
  addcollectiontocart,
  applepay,
  close,
  collectionData,
  doordashVariantData,
  gopuffVariantData,
  location,
  productsViewed,
  viewCart,
  viewCheckout,
  viewAccount,
  viewAddressModal,
  variantData,
  debug,
};
