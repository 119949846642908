import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styled, { keyframes } from 'styled-components';
import { isAccelPayMessage } from '../lib/utils';

const Root = styled.div`
  display: flex;
  gap: 4px;
`;

export const Badge = styled.span`
  background: black;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  margin: 4px 4px 4px 0;
`;

const loadingAnimation = keyframes`
  0%{ opacity:1; }
  50%{opacity:0.4;}
  100%{opacity:1;}}
`;
export const LoadingBadge = styled.span`
  animation-name: ${loadingAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  display: block;
  width: 100%;
  height: 100%;
`;

const FulfillmentBadges = ({ variantId }: { variantId: string }) => {
  const [shippingAvailable, setShippingAvailable] = useState(false);
  const [deliveryAvailable, setDeliveryAvailable] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-fetching-availability') {
        setLoading(true);
      } else if (action === 'bc-gopuff-variant-data') {
        console.log('bc-gopuff-variant-data', value);
        const inventoryStatus = value[variantId]?.inventoryStatus;
        if (['available', 'preorder'].includes(inventoryStatus)) {
          setDeliveryAvailable(true);
        } else {
          setDeliveryAvailable(false);
        }
        setLoading(false);
      } else if (action === 'bc-variant-data') {
        const inventoryStatus = value[variantId]?.inventoryStatus;
        if (['available', 'preorder'].includes(inventoryStatus)) {
          setShippingAvailable(true);
        } else {
          setShippingAvailable(false);
        }
        setLoading(false);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  return (
    <Root className="accelpay-fulfillment-badges">
      {loading ? (
        <Badge
          className="accelpay-shipping-badge accelpay-loading-badge"
          style={{
            padding: 0,
            background: 'rgba(204, 204, 204, 0.3)',
            width: 125,
            height: 26,
          }}
        >
          <LoadingBadge></LoadingBadge>
        </Badge>
      ) : (
        <>
          {shippingAvailable && (
            <Badge className="accelpay-shipping-badge">Standard Shipping</Badge>
          )}
          {deliveryAvailable && (
            <Badge className="accelpay-delivery-badge">
              30-Minute Delivery
            </Badge>
          )}
        </>
      )}
    </Root>
  );
};

export default FulfillmentBadges;
